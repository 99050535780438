<template>
  <v-card style="height:100%;width:100%;
          border: 1px solid #0bb2fa50;
          display: flex; flex-direction: column; align-items: center;
          background-color: rgba(0,0,0,0);padding: 0 50px">
    <v-row class="margin-clr" style="height: 180px; width: 100%; flex-grow: 0;">
      <v-col cols="9" class="d-flex flex-wrap" style="display: flex; flex-wrap: wrap; color: #cec9c9; font-size: 22px;">
        <v-col cols="6" style="display: flex" v-for="(info, idx) in proInfo" :key="idx">
          <div style="width: 150px">{{ info.label }}</div>
          <div>{{ info.text }}</div>
        </v-col>
      </v-col>
      <v-col cols="3" class="" style="display: flex; justify-content: center; align-items: center;">
        <v-btn dark class="btn-bg" x-large width="220" height="75" style="font-size: 26px; color: cyan" @click="goPage">
          我要做实验
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="margin-clr" style="height: 140px; width: 100%; flex-grow: 0; color: #FFF; font-size: 24px;">
      <v-col cols="12" style="display: flex; align-items: center; border-top: thin solid cyan;border-bottom: thin solid cyan;">
        <span>{{ profile }}</span>
      </v-col>
    </v-row>
    <v-row class="margin-clr flex-grow-1" style="width: 100%; color: #FFF; font-size: 24px;flex-grow: 1;">
      <v-col cols="6" style="height: 100%;">
        <div style="height: 30px; color: #faea93; margin-bottom: 10px;">{{playSrc[0].title}}</div>
        <div class="play-box">
          <v-icon v-show="!playSrc[0].isPlay" dark size="100" style="background-color: #0bb2fa30; border-radius: 50%;"
                  @click="playSrc[0].isPlay = !playSrc[0].isPlay">mdi-play</v-icon>
          <div v-if="playSrc[0].isPlay" style="width: 100%; height: 100%; padding: 10px;
               display: flex; justify-content: center; align-items: center;">
            <video-player :ref="playSrc[0].name" :name="playSrc[0].name"
                          @play="playVideo"
                          :file-name="playSrc[0].url"></video-player>
          </div>
        </div>
      </v-col>
      <v-col cols="6" style="height: 100%;">
        <div style="height: 30px; color: #faea93; margin-bottom: 10px;">{{playSrc[1].title}}</div>
        <div class="play-box">
          <v-icon v-show="!playSrc[1].isPlay" dark size="100" style="background-color: #0bb2fa30; border-radius: 50%;"
                  @click="playSrc[1].isPlay = !playSrc[1].isPlay">mdi-play</v-icon>
          <div v-if="playSrc[1].isPlay" style="width: 100%; height: 100%; padding: 10px;
               display: flex; justify-content: center; align-items: center;">
            <video-player :ref="playSrc[1].name" :name="playSrc[1].name"
                          @play="playVideo"
                          :file-name="playSrc[1].url"></video-player>
          </div>
        </div>
      </v-col>
      <!--<v-col cols="6" v-for="(item, idx) in playSrc" :key="idx" style="height: 100%;">-->
      <!--  <div style="height: 30px; color: #faea93; margin-bottom: 10px;">{{item.title}}</div>-->
      <!--  <div class="play-box">-->
      <!--    <v-icon v-show="!item.isPlay" dark size="100" style="background-color: #0bb2fa30; border-radius: 50%;"-->
      <!--            @click="item.isPlay = !item.isPlay">mdi-play</v-icon>-->
      <!--    <div v-if="item.isPlay" style="width: 100%; height: 100%; padding: 10px;-->
      <!--         display: flex; justify-content: center; align-items: center;">-->
      <!--      <video-player :ref="item.name" :name="item.name" :file-name="item.url"></video-player>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--</v-col>-->
    </v-row>
  </v-card>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer";
export default {
name: "Introduce",
  components:{VideoPlayer},
  data(){
    return {
      proInfo: [
        {label: '所属类专业：', text: '航天航空类'},
        {label: '对应专业：', text: '飞行器设计与工程'},
        {label: '学校：', text: '中国人民解放军国防科技大学'},
        {label: '负责人：', text: '江振宇'},
        {label: '试用账号：', text: 'admin'},
        {label: '使用密码：', text: '123'},

      ],
      profile: '简介：项目围绕助推滑翔战术导弹总体模块化设计与虚拟飞行验证，通过三维参数化建模、模块化设计、数据驱动虚拟验证等技术，再现导弹参数设计、装配与飞行性能验证的真实场景，支持开展结构展开与装配、总体参数确定、模块化设计、飞行弹道规划和虚拟验证等实验单元，支撑学员递进式完成助推滑翔战术导弹结构认知、综合实践和探究创新，力促学生实现知识能力素质有机融合，促进懂装备、善应用的高素质人才培养。',
      playSrc:[
        {isPlay: false, name: 'play1', title: '项目简介视频', url:'video_1.mp4'},
        {isPlay: false, name: 'play2', title: '项目教学引导视频', url:'video_2.mp4'},
      ]
    }
  },
  methods:{
    goPage(){
      this.$router.push({name: 'Login'})
    },
    playVideo(name){
      this.playSrc.forEach(item => {
        if(item.name !== name){
          // item.isPlay = !item.isPlay
          // this.$refs[name].setPause()
          switch (item.name) {
            case 'play1':
              this.$refs.play1.setPause()
              break
            case 'play2':
              this.$refs.play2.setPause()
              break
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.margin-clr{
  margin: 0px !important;
}
.btn-bg{
  background-image: url("~@/assets/bg/btn.png");
}
.play-box{
  width: 100%;
  height: calc(100% - 40px);
  background: url("~@/assets/bg/videobj.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-out{
  border: 1px solid #00ffff30;
  border-radius: 5px;
}
</style>