<template>
  <div class="layout">
    <!--header-->
    <div class="header-box" style="position: relative">
      <!--<div class="border-red" style="position: absolute; left: 0; width: 25%;">-->
      <!--  <v-img class="ml-2" src="@/assets/png/logotitle.png" height="80" contain></v-img>-->
      <!--</div>-->
      <div class="" style="position: absolute; left: 0; width: 100%;">
        <v-img class="ml-2" src="@/assets/bg/head.png" height="20" contain></v-img>
      </div>
      <span style="font-size: 30px; color: #0bb2fa" class="">{{ title }}</span>
    </div>
    <!--content-->
    <v-container fluid class="pa-0 main-contain">
      <!--<keep-alive>-->
      <!--  <router-view></router-view>-->
      <!--</keep-alive>-->
      <introduce></introduce>
    </v-container>
  </div>
</template>

<script>
import Introduce from "@/views/Home/Introduce";
export default {
  name: "index",
  components:{
    Introduce,
  },
  data(){
    return {
      title: '助推滑翔导弹模块化设计与飞行验证',
    }
  },
  methods:{
    goPage(tab){
      switch (tab) {
        case 0:
          if (this.$route.name !== 'StructTabs') {
            this.$router.push('/')
          }
          break
        case 1:
          // 飞行控制少了一级页面
          if (this.$route.name !== 'PlanCtrl') {
            this.$router.push({
              name: 'PlanCtrl'
            })
          }
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-box{
  width: 100%;
  height: 60px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-image: url("~@/assets/bg/head.png");
  //background-size: 100%;
}
.layout{
  background-image: url("~@/assets/bg/bj.png");
  display: flex;
  flex-direction: column;
}
.main-contain{
  width: 100%;
  height: calc(100vh - 60px);
}
</style>
