<template>
    <video-player
      class="video-player vjs-custom-skin"
      style="width: 100%; height: 100%;"
      ref="videoPlayer"
      @play="startPlay(name)"
      :playsinline="true"
      :options="playerOptions"
    ></video-player>
</template>

<script>
// 导入组件
import { videoPlayer } from "vue-video-player";
import 'video.js/dist/video-js.css'
import "videojs-flash";

export default {
  name: "VideoPlayer",
  components: {
    videoPlayer,
  },
  props:{
    fileName:{
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileAreaHeight: 300,
      fileType: "mp4", // 资源的类型
      fileUrl: "http://localhost/hightspeedforceM.mp4", // 资源的路径地址
    };
  },
  computed: {
    playerOptions() {
      // 使用计算属性
      const playerOptionsObj = {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        // preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误
            src: process.env.VUE_APP_NET + this.fileName, //this.fileName //'http://192.168.1.107:8283/video/micro/1.mp4' // // url地址
          },
        ],
        // width: document.documentElement.clientWidth,
        height: 360, // 设置高度，fluid需要设置成flase
        notSupportedMessage: "此视频暂无法播放...", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      };
      return playerOptionsObj;
    },

  },
  methods:{
    setPause(){
      this.$refs.videoPlayer.player.pause()
    },
    startPlay(name){
      console.log('play:', name)
      this.$emit('play', name)
    }
  }
};
</script>
<style lang="scss" scoped>
.video-js .vjs-big-play-button {
  /*对播放按钮的样式进行设置*/
}
::v-deep{
  .video-js.vjs-fluid{
    height: 100% !important;
  }
}
</style>
